import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid } from "@mui/material";

import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";

import { SocketContext } from "../../../context/SocketContext";
import { SocketNFCContext } from "../../../context/SocketNFC";
import toast, { Toaster } from "react-hot-toast";

import LastUsers from "./LastUsers";

import ModalAnonUserRegistration from "../UserModals/ModalAnonUserRegistration";
import ModalHybridUserRegistration from "../UserModals/ModalHybridUserRegistration";
import ModalHybridUserEdit from "../UserModals/ModalHybridUserEdit";
import ModalFullUserRegistration from "../UserModals/ModalFullUserRegistration";
import ModalFullUserEdit from "../UserModals/ModalFullUserEdit";
import ModalCashoutPending from "../UserModals/ModalCashoutPending";
import ModalUserInfo from "../UserModals/ModalUserInfo";
import ModalKiosk from "../Kiosk/ModalKiosk";

import CardPayment from "../PaymentScreen/CardPayment";
import ModalSpillerkonto from "../UserModals/ModalSpillerkonto";

import LastUsersFiltered from "./LastUsersFiltered";

import HeaderSearcher from "./HeaderSearcher";
import HeaderUserDescription from "./HeaderUserDescription";
import TotalButton from "./TotalButton";
import TransactionList from "./TransactionList";

import ModalUserFound from "./ModalUserFound";
import ModalUserNotFound from "./ModalUserNotFound";
import ModalOpenSession from "../UserModals/ModalOpenSession";
import ModalEditUserLimits from "../UserModals/ModalEditUserLimits";
import ModalCardToCash from "../UserModals/ModalCardToCash";
import ModalIsLimitSurpassed from "../UserModals/ModalIsLimitSurpassed";
import ModalIsVerificationNeeded from "../UserModals/ModalIsVerificationNeeded";
import ModalIsLimitSurpassedTransaction from "../UserModals/ModalIsLimitSurpassedTransaction";
import ModalDifferentParent from "../UserModals/ModalDifferentParent";
import ModalUserInfoSibling from "../UserModals/ModalUserInfoSibling";
import ModalPictureVerification from "../UserModals/ModalPictureVerification";
import ModalPictureVerificationWarning from "../UserModals/ModalPictureVerificationWarning";
import ModalProfileImage from "../UserModals/ModalProfileImage";
import ModalPictureVerificationPreRegister from "../UserModals/ModalPictureVerificationPreRegister";
import ModalUserInfoOK from "../UserModals/ModalUserInfoOK";
import ModalDifferentParentOK from "../UserModals/ModalDifferentParentOK";
import ModalSignatureID from "../UserModals/ModalSignatureID";
import ModalSpillerkontoPayment from "../UserModals/ModalSpillerkontoPayment";

function Transactions() {
  const { t } = useTranslation();
  const {
    isLoadingScreen,
    updateIsLoadingScreen,
    showSpillerkontoPayment,
    OKBingoID,
    showModalSignatureID,
    showModalDifferentParentOK,
    updateShowModalDifferentParentOK,
    showUserInfoOK,
    updateShowUserInfoOK,
    updateCurrentTicketOK,
    updateIsHybridUserRegistration,
    updateIsFullUserEditing,
    updateIsFullUserRegistration,
    showModalPictureVerificationPreRegister,
    showModalProfileImage,
    updateUserImageInfo,
    updateScannedTicket,
    updateShowUserInfoSibling,
    showUserInfoSibling,
    updateAdoptedTicket,
    updateShowModalDifferentParent,
    showModalDifferentParent,
    updateIsVerificationNeeded,
    currentUser,
    userInfo,
    updateShowUserInfo,
    isFullUser,
    updateIsCashoutPending,
    showModalUserFound,
    showModalUserNotFound,
    updateRefreshUser,
    session,
    operator,
    hall,
    userLogin,
    updateUserLogin,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserInfo,
    updateIsFullUser,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    print,
    updateUserChildren,
    updateUserParent,
    updateParentInfo,
    updateUserSiblings,
    isCashoutPending,
    isAnonUserRegistration,
    isHybridUserEditing,
    updateIsHybridUserEditing,
    isHybridUserRegistration,
    isFullUserRegistration,
    isFullUserEditing,
    showUserInfo,
    showCard,
    showSpillerkonto,
    showKiosk,
    updateShowModalUserNotFound,
    updateShowModalUserFound,
    userToSearch,
    updateOpenSessionTerminal,
    openSessionTerminal,
    showModalEditUserLimits,
    showCardToCash,

    showIsLimitSurpassed,
    parentInfo,
    updateShowIsLimitSurpassed,
    isLimitSurpassed,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateShowModalIsVerificationNeeded,
    showModalIsVerificationNeeded,
    userLimits,
    updateUserLimits,
    showIsLimitSurpassedTransaction,
    showModalPictureVerification,
    showModalPictureVerificationWarning,

    updateIsPictureVerificationNeeded,
    updateShowModalPictureVerificationWarning,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);
  const socketNFC = useContext(SocketNFCContext);

  const [usersResults, setUsersResults] = useState([]);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const printRef = useRef(print);
  useEffect(() => {
    printRef.current = print;
  }, [print]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    console.log("userInfo change", userInfo);

    userInfoRef.current = userInfo;
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  useEffect(() => {
    console.log("Cambio userInfor", userInfo);
    if (!userInfo.username) {
      return;
    }

    if (currentUser === "Gjest") {
      return;
    }
    if (userInfo.username.startsWith("anon")) {
      updateShowUserInfo(true);
    }
    socket.emit("openSessions", userInfo.id);

    if (userInfo.balances.cashout > 0 && isFullUser) {
      updateIsCashoutPending(true);
    }

    updateIsVerificationNeeded(false);
    setTimeout(() => {
      console.log("parentInfo", parentInfo);
      console.log("userInfo", userInfo);
      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      if (
        (parentInfo && !parentInfo["Verification method"]) ||
        (!parentInfo &&
          !userInfo["Verification method"] &&
          !userInfo.username.startsWith("anon") &&
          !userInfo.username.startsWith("hybrid"))
      ) {
        updateIsVerificationNeeded(true);
        updateShowModalIsVerificationNeeded(true);
      }
    }, 300);

    //* IMAGE VERIFICATION
    /*
    setTimeout(() => {
      if (
        (parentInfo && !parentInfo["Verification image ID"]) ||
        (!parentInfo &&
          !userInfo["Verification image ID"] &&
          !userInfo.username.startsWith("anon"))
      ) {
        updateIsPictureVerificationNeeded(true);
        updateShowModalPictureVerificationWarning(true);
      }
    }, 400);
    */
  }, [userLogin]);

  //* Login task after search, used in several places
  const handleLogin = (data) => {
    console.log("handleLogin", data);
    if (data.records.length > 1) {
      setUsersResults(data.records);
      updateShowModalUserFound(true);
      console.log("Users found");
      return;
    }
    if (data.records.length === 0) {
      updateShowModalUserNotFound(true);
      console.log("Users not found");
      return;
    }

    updateUserLogin(true);
    updateUserInfo(data.records[0]);
    updateCurrentUser(data.records[0]["7 digit PIN"]);
    updateCurrentUserID(data.records[0]["id"]);
    //updateUserImageInfo(data.userProfileImage);
    //* IMAGE VERIFICATION
    /*
    const blob = new Blob([data.userProfileImage.image], {
      type: data.userProfileImage.mimeType,
    });
    const url = URL.createObjectURL(blob);

    // Update state with the image info and URL
    updateUserImageInfo({
      blobName: data.blobName,
      imageUrl: url,
      mimeType: data.mimeType,
    });
    */

    if (data.siblings) {
      updateUserSiblings(data.siblings);
    }

    if (data.children) {
      updateUserChildren(data.children);
    }

    var dataToRegisterObj = [];
    dataToRegisterObj.push(data.records[0]);
    if (
      data.records[0].username.slice(0, 4) === "anon" ||
      (data.records[0].firstname === "" && data.records[0].mobile === "")
    ) {
      //ANON
      updateIsFullUser(false);
      updateIsHybridUser(false);
      updateIsAnonUser(true);
      if (data.parent) {
        updateUserParent(data.parent);
        var data = {
          auth_value: data.parent.parentID,
          searchByID: true,
          action: "searchMemberParent",
          session: session,
        };
        socket.emit("searchMemberParent", data);
      }
      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }
    } else if (data.records[0].username.slice(0, 6) === "hybrid") {
      //Hybrid, don't do shit
      updateIsFullUser(false);
      updateIsHybridUser(true);
      updateIsAnonUser(false);
    } else {
      //Parent, save the children if exist
      if (data.children) {
        updateUserChildren(data.children);
      }
      updateIsFullUser(true);
      updateIsHybridUser(false);
      updateIsAnonUser(false);
    }

    updateIsFastUserLogin(false);

    setTimeout(() => {
      console.log("timeout", userInfoRef.current.username);
      if (userInfoRef.current.username.startsWith("anon")) {
        updateShowUserInfo(true);
      }
    }, 500);

    var dataToRegister = {
      action: "registerLastUsers",
      operator: operator,
      bingoName: hall,
      sessionID: session,
      userInfo: dataToRegisterObj[0],
    };

    registerLastUsers(dataToRegister);

    if (isHybridUserEditing) {
      updateIsHybridUserEditing(false);
    }
    if (isFullUserEditing) {
      updateIsFullUserEditing(false);
    }
    if (isHybridUserRegistration) {
      updateIsHybridUserRegistration(false);
    }
    if (isFullUserRegistration) {
      updateIsFullUserRegistration(false);
    }
  };

  useEffect(() => {
    const handleNewShoppingCarts = (data) => {
      //console.log("newShoppingCarts", data);
    };

    const handleSearchMember = (data) => {
      console.log("searchMember trans", data);
      updateRefreshUser(false);

      if (!data || !data.records) {
        toast.error(t("error.error"));
        return;
      }
      handleLogin(data);
    };

    const handleSearchMemberParent = (data) => {
      updateParentInfo(data.records[0]);
    };

    const handleOpenSessions = (data) => {
      if (data) {
        updateOpenSessionTerminal(data);
      }
    };

    const handleCheckLimitStatus = (data) => {
      console.log("checkLimitStatus", data);

      updateUserLimits(data);

      if (data.isMonthly) {
        updateIsMonthlyLimitSurpassed(true);
        updateIsLimitSurpassed(true);
        updateShowIsLimitSurpassed(true);
        return;
      }
      if (data.isDaily) {
        updateIsDailyLimitSurpassed(true);
        updateIsLimitSurpassed(true);
        updateShowIsLimitSurpassed(true);
        return;
      }
    };

    const handleSearchMemberHidden = (data) => {
      if (!data || !data.records) {
        toast.error(t("error.error"));
        return;
      }

      updateAdoptedTicket({});
      updateScannedTicket({});
      updateOpenSessionTerminal(false);

      updateShowModalDifferentParentOK(false);
      updateShowModalDifferentParent(false);
      updateShowUserInfoSibling(false);
      updateShowUserInfo(false);
      updateShowUserInfoOK(false);

      if (
        parseInt(data.parent.parentID) !== userInfoRef.current.id &&
        parseInt(data.parent.parentID) !== parentInfoRef.current.id
      ) {
        var adoptedTicket = {
          parent: data.parent,
          ticket: data.records[0],
        };
        socket.emit("openSessionsAdopted", data.records[0].id);

        updateAdoptedTicket(adoptedTicket);
        updateShowModalDifferentParent(true);

        return;
      }

      if (
        parseInt(data.parent.parentID) === userInfoRef.current.id ||
        parseInt(data.parent.parentID) === parentInfoRef.current.id
      ) {
        //Change this to reload the user like in the list
        //socket.emit("openSessions", data.records[0].id);
        //updateScannedTicket(data.records[0]);
        //updateShowUserInfoSibling(true);

        //This doesn't show the user info modal, need to recheck it
        var data = {
          auth_value: data.records[0]["7 digit PIN"],
          searchByPin: true,
          action: "searchMember",
          session: session,
        };
        socket.emit("searchMemberMultiScan", data);
      }
    };

    const handleSearchParentOKD = (data) => {
      console.log("searchParentOKD", data);
      if (!data) {
        toast.error(t("error.error"));
        return;
      }

      updateAdoptedTicket({});
      updateScannedTicket({});
      updateOpenSessionTerminal(false);

      updateShowModalDifferentParentOK(false);
      updateShowModalDifferentParent(false);
      updateShowUserInfoSibling(false);
      updateShowUserInfo(false);
      updateShowUserInfoOK(false);

      updateCurrentTicketOK({ okID: data.okID });

      if (
        parseInt(data.parent.id) !== userInfoRef.current.id &&
        parseInt(data.parent.id) !== parentInfoRef.current.id
      ) {
        var adoptedTicket = {
          parent: data.parent,
          ticket: data.okID,
        };
        updateAdoptedTicket(adoptedTicket.parent);
        console.log("Diferente PARENT");
        setTimeout(() => {
          //updateShowUserInfoOK(true);
          updateShowModalDifferentParentOK(true);
        }, 500);
        return;
      } else {
        console.log("MISMO PARENT");
        setTimeout(() => {
          updateShowUserInfoOK(true);
        }, 500);
        return;
      }
    };

    const handleOpenSessionsAdopted = (data) => {
      if (data) {
        updateOpenSessionTerminal(data);
      }
    };

    const handleSearchMemberMultiScan = (data) => {
      if (!data || !data.records) {
        toast.error(t("error.error"));
        return;
      }
      socket.emit("openSessions", data.records[0]["id"]);

      handleLogin(data);
    };

    const handleSearchTicketOKD = (data) => {
      console.log("handleSearchTicketOKD", data);
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      console.log(data.okID);
      updateCurrentTicketOK({ okID: data.okID });
      handleLogin(data);

      setTimeout(() => {
        updateShowUserInfoOK(true);
      }, 500);
    };

    socket.on("checkLimitStatus", handleCheckLimitStatus);
    socket.on("newShoppingCarts", handleNewShoppingCarts);
    socket.on("searchMember", handleSearchMember);
    socket.on("searchMemberMultiScan", handleSearchMemberMultiScan);
    socket.on("searchMemberHidden", handleSearchMemberHidden);
    socket.on("searchParentOKD", handleSearchParentOKD);
    socket.on("searchMemberParent", handleSearchMemberParent);
    socket.on("openSessions", handleOpenSessions);
    socket.on("openSessionsAdopted", handleOpenSessionsAdopted);
    socket.on("searchTicketOKD", handleSearchTicketOKD);

    return () => {
      socket.off("checkLimitStatus", handleCheckLimitStatus);
      socket.off("newShoppingCarts", handleNewShoppingCarts);
      socket.off("searchMember", handleSearchMember);
      socket.off("searchMemberHidden", handleSearchMemberHidden);
      socket.off("searchMemberParent", handleSearchMemberParent);
      socket.off("openSessions", handleOpenSessions);
      socket.off("openSessionsAdopted", handleOpenSessionsAdopted);
      socket.off("searchMemberMultiScan", handleSearchMemberMultiScan);
      socket.off("searchTicketOKD", handleSearchTicketOKD);
      socket.off("searchParentOKD", handleSearchParentOKD);
    };
  }, [socket]);

  useEffect(() => {
    socketNFC.on("cardDetected", (data) => {
      if (userLogin) {
        return;
      }
      modalNewCard(data);
    });
  }, [socketNFC]);

  const registerLastUsers = (data) => {
    socket.emit("registerLastUsers", data);
  };

  const modalNewCard = (cardID) => {
    confirmAlert({
      title: t("sales.newCardMessage") + " ID: " + cardID,
      message: "",
      buttons: [
        {
          label: t("sales.no"),
          onClick: () => {},
          className: "btn-custom-no",
        },

        {
          label: t("sales.yes"),
          onClick: () => {
            setTimeout(() => {
              updateIsHybridUserEditing(true);
            }, 400);
          },
          className: "btn-custom-yes",
        },
      ],
    });
  };

  function getIdFromLink(str) {
    if (!str.startsWith("https")) {
      return false;
    }

    try {
      const url = new URL(str);

      const id = url.searchParams.get("id");
      return id;
    } catch (error) {
      return false;
    }
  }

  return (
    <Grid
      container
      sx={{
        height: "100%",
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: "9999999 !important",
            fontSize: "24px",
          },
        }}
        containerStyle={{
          zIndex: 99999999,
        }}
      />

      {/* MODALS */}
      {showModalUserFound && <ModalUserFound users={usersResults} />}
      {showModalUserNotFound && <ModalUserNotFound />}
      {isFullUserRegistration && <ModalFullUserRegistration />}
      {isFullUserEditing && <ModalFullUserEdit />}
      {isHybridUserRegistration && <ModalHybridUserRegistration />}
      {isHybridUserEditing && <ModalHybridUserEdit />}
      {isAnonUserRegistration && <ModalAnonUserRegistration />}
      {isCashoutPending && <ModalCashoutPending />}
      {showUserInfo && <ModalUserInfo />}
      {showCard && <CardPayment />}
      {showSpillerkonto && <ModalSpillerkonto />}
      {showKiosk && <ModalKiosk />}
      {showModalEditUserLimits && <ModalEditUserLimits />}
      {showCardToCash && <ModalCardToCash />}
      {openSessionTerminal && !showModalDifferentParent && <ModalOpenSession />}
      {showIsLimitSurpassed && <ModalIsLimitSurpassed />}
      {showModalIsVerificationNeeded && <ModalIsVerificationNeeded />}
      {showIsLimitSurpassedTransaction && <ModalIsLimitSurpassedTransaction />}
      {showModalDifferentParent && <ModalDifferentParent />}
      {showModalDifferentParentOK && <ModalDifferentParentOK />}
      {showUserInfoSibling && !openSessionTerminal && <ModalUserInfoSibling />}
      {showModalPictureVerificationWarning && (
        <ModalPictureVerificationWarning />
      )}
      {showModalPictureVerification && <ModalPictureVerification />}
      {showModalPictureVerificationPreRegister && (
        <ModalPictureVerificationPreRegister />
      )}
      {showModalProfileImage && <ModalProfileImage />}
      {showUserInfoOK && <ModalUserInfoOK />}
      {showModalSignatureID && <ModalSignatureID />}
      {showSpillerkontoPayment && <ModalSpillerkontoPayment />}

      {/* HEADER CONTAINER */}
      {!userLogin ? <HeaderSearcher /> : <HeaderUserDescription />}

      {userLogin ? (
        <>
          {/* TRANSLIST CONTAINER AND TOTAL BUTTON */}
          <TransactionList />
          <TotalButton />
        </>
      ) : (
        <>{userToSearch.length > 0 ? <LastUsersFiltered /> : <LastUsers />}</>
      )}
    </Grid>
  );
}

export default Transactions;

import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast, { Toaster } from "react-hot-toast";

function ModalSignatureID() {
  const { t } = useTranslation();

  const {
    hall,
    session,
    operator,
    userInfo,
    parentInfo,
    showModalPictureVerification,
    updateFocusOnMainScreen,
    logoutUser,
    updateShowModalSignatureID,
    updateShowModalPictureVerificationWarning,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserLogin,
    updateUserInfo,
    updateIsFastUserLogin,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFullUser,
    updateUserParent,
    updateUserChildren,
    updateUserSiblings,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
    imageVerificationTemp,
    updateImageVerificationTemp,
  } = useContext(AppContext);

  const [isSuccess, setIsSuccess] = useState(false);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const [streams, setStreams] = useState([]);
  const [capturedImages, setCapturedImages] = useState([]);

  // Function to start the media stream
  const startVideo = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length > 0) {
        const streams = await Promise.all(
          videoDevices.slice(0, 2).map(async (device, index) => {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: { deviceId: device.deviceId },
            });

            if (index === 0 && videoRef1.current) {
              videoRef1.current.srcObject = stream;
              videoRef1.current.play();
            } else if (index === 1 && videoRef2.current) {
              videoRef2.current.srcObject = stream;
              videoRef2.current.play();
            }

            return stream;
          })
        );
        setStreams(streams);
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const stopVideo = () => {
    streams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
  };

  useEffect(() => {
    startVideo();

    // Cleanup function to stop the media streams
    return () => {
      stopVideo();
    };
  }, []);

  // Function to handle the capture
  const handleCapture = (index) => {
    const video = index === 0 ? videoRef1.current : videoRef2.current;
    if (!video) return;

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const newCapturedImages = [...capturedImages];
    newCapturedImages[index] = canvas.toDataURL("image/png");
    setCapturedImages(newCapturedImages);
  };

  // Function to restart the camera
  const handleRestart = () => {
    setCapturedImages([]); // Clear the captured images
    startVideo(); // Restart the video streams
  };

  const handleUpload = async () => {
    if (!capturedImages.length) return;

    for (let i = 0; i < capturedImages.length; i++) {
      const imageUrl = capturedImages[i];

      if (imageUrl) {
        // Convert the data URL to a buffer
        const byteString = atob(imageUrl.split(",")[1]);
        const mimeType = imageUrl.split(",")[0].split(":")[1].split(";")[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let j = 0; j < byteString.length; j++) {
          intArray[j] = byteString.charCodeAt(j);
        }

        const currentDateTimeString = getCurrentDateTimeString();

        updateImageVerificationTemp({
          fileBuffer: intArray,
          fileName: parentInfo
            ? parentInfo.id + `_${currentDateTimeString}.png`
            : userInfo.id + `_${currentDateTimeString}.png`,
        });
      }
    }

    stopVideo();
    updateShowModalSignatureID(false);
  };

  function getCurrentDateTimeString() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const cancel = async () => {
    stopVideo();
    updateShowModalSignatureID(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <>
              <h1>{t("sales.photoUploadTitle")} </h1>
              <p>{t("sales.photoUploadTextSignature")} </p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {[0, 1].map((index) => (
                  <div
                    key={index}
                    style={{
                      width: "50%",
                      height: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {capturedImages[index] ? (
                      <img
                        src={capturedImages[index]}
                        alt={`Captured ${index + 1}`}
                        style={{ width: "100%", height: "300px" }}
                      />
                    ) : (
                      <video
                        ref={index === 0 ? videoRef1 : videoRef2}
                        autoPlay
                        playsInline
                        style={{ width: "100%", height: "300px" }}
                      >
                        {streams[index]
                          ? "Loading video..."
                          : "Video stream not available."}
                      </video>
                    )}
                    <button
                      className="btn-custom-camara"
                      onClick={
                        capturedImages[index]
                          ? handleRestart
                          : () => handleCapture(index)
                      }
                    >
                      {capturedImages[index]
                        ? t("report.resetCamera")
                        : t("report.capturePicture")}
                    </button>
                  </div>
                ))}
              </div>

              <div
                className="react-confirm-alert-button-group"
                style={{ marginTop: "55px" }}
              >
                <button
                  className="btn-custom-yes"
                  style={{
                    opacity: capturedImages[0] || capturedImages[1] ? 1 : 0.5,
                    backgroundColor:
                      capturedImages[0] || capturedImages[1]
                        ? "#009e82 !important"
                        : "grey !important",
                  }}
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  {t("sales.confirm")}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancel();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSignatureID;

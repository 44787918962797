import React, { useContext, useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";

import {
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
  BorderColor as BorderColorIcon,
  Person as PersonIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/Context";

import { SocketContext } from "../../../context/SocketContext";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";
import toast from "react-hot-toast";

function HeaderSearcher({}) {
  const { t } = useTranslation();

  const { socket } = useContext(SocketContext);

  const {
    updateShowModalProfileImage,
    userImageInfo,
    isSellerOpen,
    isPaymentOpen,
    session,
    hall,
    operator,
    parentInfo,
    updateRefreshUser,
    updateUserLogin,
    updateUserInfo,
    updateCurrentUserID,
    updateCurrentUser,
    updateUserSiblings,
    updateUserChildren,
    updateIsFullUser,
    updateIsHybridUser,
    updateIsAnonUser,
    updateIsFastUserLogin,
    updateUserParent,
    updateShowModalUserFound,
    updateShowModalUserNotFound,
    userParent,
    currentUser,
    userInfo,
    updateOpenSessionTerminal,
    updateIsFullUserEditing,
    isHybridUser,
    isAnonUser,
    isFullUser,
    updateIsHybridUserEditing,
    updateIsFullUserRegistration,

    favUsers,
    updateFavUsers,
  } = useContext(AppContext);

  useEffect(() => {
    const handleOpenSessions = (data) => {
      if (data) {
        updateOpenSessionTerminal(data);
      }
    };

    const handleAddFavourite = (data) => {
      //setLastUsers(data);
      if (!data) {
        updateFavUsers([]);
        return;
      }
      data.sort((a, b) => new Date(b.date) - new Date(a.date));

      updateFavUsers(data);
    };

    const handleRemoveFavourite = (data) => {
      //setLastUsers(data);
      if (!data) {
        updateFavUsers([]);
        return;
      }
      data.sort((a, b) => new Date(b.date) - new Date(a.date));

      updateFavUsers(data);
    };

    socket.on("openSessions", handleOpenSessions);
    socket.on("addFavourite", handleAddFavourite);
    socket.on("removeFavourite", handleRemoveFavourite);
    return () => {
      socket.off("openSessions", handleOpenSessions);
      socket.off("addFavourite", handleAddFavourite);
      socket.off("removeFavourite", handleRemoveFavourite);
    };
  }, [socket]);

  const editUser = () => {
    if (isSellerOpen || isPaymentOpen) {
      toast.error(t("sales.editUserBlock"));
      return;
    }
    if (isFullUser) {
      updateIsFullUserEditing(true);
      return;
    }
    if (isHybridUser) {
      editHybridUser();
      return;
    }
    if (isAnonUser) {
      editAnonUser();
      return;
    }
  };

  const editAnonUser = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: "600px" }}>
            <h1>{t("sales.anonToUser")}</h1>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  onClose();
                  updateIsHybridUserEditing(true);
                }}
              >
                {t("sales.newUser")}
              </button>

              <button
                className="btn-custom-yes"
                onClick={() => {
                  onClose();
                  updateIsFullUserRegistration(true);
                }}
              >
                {t("sales.editHybridToFull")}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  onClose();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const editHybridUser = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: "600px" }}>
            <h1>{t("sales.hybridToUser")}</h1>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  onClose();
                  updateIsHybridUserEditing(true);
                }}
              >
                {t("sales.editHybridUser")}
              </button>

              <button
                className="btn-custom-yes"
                onClick={() => {
                  onClose();
                  updateIsFullUserEditing(true);
                }}
              >
                {t("sales.editHybridToFull")}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  onClose();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const [scannedString, setScannedString] = useState("");

  const isSellerOpenRef = useRef(isSellerOpen);
  useEffect(() => {
    isSellerOpenRef.current = isSellerOpen;
  }, [isSellerOpen]);

  const isPaymentOpenRef = useRef(isPaymentOpen);
  useEffect(() => {
    isPaymentOpenRef.current = isPaymentOpen;
  }, [isPaymentOpen]);

  useEffect(() => {
    let accumulatedString = "";

    const handleKeyPress = (event) => {
      if (isSellerOpenRef.current || isPaymentOpenRef.current) {
        return;
      }

      const key = event.key;

      // Accumulate keys for the scanned string meanwhile is not an enter
      if (key !== "Enter") {
        accumulatedString += key;
      } else {
        console.log("Scanned string:", accumulatedString);

        function parseString(input) {
          // Define an object to store the results
          const result = {};

          // Updated regex to find digits after 'pid', 'mob', 'id', 'okd' with either '¡' or '=' as delimiter
          const urlPattern = /(pid|mob|id|okd)[¡=](\d+)/g;

          // Regex to match strings like 'k1' or 'M' followed by exactly 7 digits
          const barcodePattern = /^(k1|M)(\d{7})$/i;

          // Check if the string starts with 'k1' or 'M' followed by 7 digits
          if (barcodePattern.test(input)) {
            const match = input.match(barcodePattern);
            result.barcode = match[0]; // Store the matched string
          }

          // Find all occurrences of pid, mob, id, okd and their corresponding digits
          let match;
          while ((match = urlPattern.exec(input)) !== null) {
            const key = match[1]; // pid, mob, id, or okd
            const value = match[2]; // the digits after the key
            result[key] = value; // Store in the result object
          }

          return result;
        }

        let decodedUrl = parseString(accumulatedString);
        console.log("Decoded URL:", decodedUrl);

        processExtractedData(decodedUrl);

        // Clear accumulated string after processing
        accumulatedString = "";
      }
    };

    const processExtractedData = (data) => {
      // Process the extracted data
      console.log("Processing extracted data:", data);
      if (data.okd) {
        console.log("Searching Parent for OK:", data.okd);
        var data = {
          okID: data.okd,
          action: "searchParentOKD",
          session: session,
          searchByID: true,
        };
        socket.emit("searchParentOKD", data);
      }

      if (data.pid) {
        console.log("Handling PID:", data.pid);
      }

      if (data.id) {
        console.log("Handling ID:", data.id);
        const dataToRequest = {
          auth_value: data.id,
          action: "searchMemberHidden",
          session: session,
        };
        socket.emit("searchMemberHiddenByID", dataToRequest);
      }

      if (data.barcode) {
        console.log("Handling barcode pattern:", data.barcode);
        const dataToRequest = {
          auth_value: data.barcode.substring(2),
          action: "searchMemberHidden",
          session: session,
        };
        console.log("searchMemberHidden", dataToRequest);
        socket.emit("searchMemberHidden", dataToRequest);
      }
    };

    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const addToFavourite = () => {
    if (isPincodePresentIconCheck()) {
      var data = {
        user7Pincode: parentInfo
          ? parentInfo["7 digit PIN"]
          : userInfo["7 digit PIN"],
        operator: operator,
        bingoName: hall,
        action: "removeFavourite",
        session: session,
        username: parentInfo ? parentInfo.username : userInfo.username,
        lastname: parentInfo ? parentInfo.lastname : userInfo.lastname,
        phonenumber: parentInfo ? parentInfo.mobile : userInfo.mobile,
        userID: parentInfo ? parentInfo.id : userInfo.id,
        firstname: parentInfo ? parentInfo.firstname : userInfo.firstname,
      };
      socket.emit("removeFavourite", data);
      return;
    }

    var data = {
      user7Pincode: parentInfo
        ? parentInfo["7 digit PIN"]
        : userInfo["7 digit PIN"],
      operator: operator,
      bingoName: hall,
      action: "addFavourite",
      session: session,
      username: parentInfo ? parentInfo.username : userInfo.username,
      lastname: parentInfo ? parentInfo.lastname : userInfo.lastname,
      phonenumber: parentInfo ? parentInfo.mobile : userInfo.mobile,
      userID: parentInfo ? parentInfo.id : userInfo.id,
      firstname: parentInfo ? parentInfo.firstname : userInfo.firstname,
    };
    socket.emit("addFavourite", data);
  };

  function isPincodePresent(array, objectToCheck) {
    for (const obj of array) {
      if (obj.user7Pincode === objectToCheck.user7Pincode) {
        return true;
      }
    }
    return false;
  }

  function isPincodePresentIconCheck() {
    let objectToCheck = userInfo;
    if (parentInfo) {
      objectToCheck = parentInfo;
    }
    for (const obj of favUsers) {
      if (obj.user7Pincode === objectToCheck["7 digit PIN"]) {
        return true;
      }
    }
    return false;
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        height: "325px",
        width: "100%",
        marginLeft: 0,
        marginBottom: 0,
        backgroundColor: "#15192b",
        padding: 0,
        borderBottom: "5px solid black",
        marginTop: "-50px",
        position: "relative",
      }}
    >
      {/*CONTAINER*/}
      <Grid
        container
        alignItems="center"
        sx={{
          height: "200px",
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "25px",
          marginTop: "35px",
        }}
      >
        {/*USER IMAGE*/}
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            padding: "15px",
            marginTop: "25px",
          }}
          onClick={() => {
            if (!userImageInfo) {
              return;
            }
            updateShowModalProfileImage(true);
          }}
        >
          {userImageInfo ? (
            <img
              style={{
                width: "auto",
                height: "100%",
                objectFit: "contain",
                border: "1px solid white",
                borderRadius: "5px",
                padding: "15px",
                backgroundColor: "#000",
              }}
              src={userImageInfo.imageUrl}
              alt={userImageInfo.blobName}
            />
          ) : (
            <div
              style={{
                width: "140px",
                height: "100%",
                border: "1px solid white",
                borderRadius: "5px",
                padding: "15px",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                backgroundColor: "#000",
              }}
            >
              <PersonIcon style={{ fontSize: 100 }} />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{
          height: "125px",
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 0px",
          marginTop: "-30px",
        }}
      >
        {/*NAME, SURNAME, EDITION*/}
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",

              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                height: "50%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  margin: "0px 0",
                  textAlign: "center",
                }}
              >
                {!parentInfo ? (
                  <>
                    {!userInfo.firstname ||
                    userInfo.firstname === "" ||
                    userInfo.firstname === "Uregistrert"
                      ? t("sales.unregistered")
                      : userInfo.firstname}
                  </>
                ) : (
                  <>
                    {!parentInfo.firstname ||
                    parentInfo.firstname === "" ||
                    parentInfo.firstname === "Uregistrert"
                      ? t("sales.unregistered")
                      : parentInfo.firstname}
                  </>
                )}
              </p>
              <p
                style={{
                  fontSize: "20px",
                  margin: "0px 0",
                  textAlign: "center",
                }}
              >
                {!parentInfo ? (
                  <>
                    {!userInfo.lastname ||
                    userInfo.lastname === "" ||
                    userInfo.lastname === "Uregistrert"
                      ? ""
                      : userInfo.lastname}
                  </>
                ) : (
                  <>
                    {!parentInfo.lastname ||
                    parentInfo.lastname === "" ||
                    parentInfo.lastname === "Uregistrert"
                      ? ""
                      : parentInfo.lastname}
                  </>
                )}
              </p>
              <p
                style={{
                  fontSize: "20px",
                  margin: "0px 0",
                  textAlign: "center",
                }}
              >
                {!parentInfo ? (
                  <>
                    {userParent
                      ? userParent.userInfo.mobile
                      : !userParent &&
                        (!userInfo.mobile || userInfo.mobile === "")
                      ? "-"
                      : userInfo.mobile}
                  </>
                ) : (
                  <>
                    {!parentInfo.mobile || parentInfo.mobile === ""
                      ? "-"
                      : parentInfo.mobile}
                  </>
                )}
              </p>

              {/* Left-side icon */}
              {currentUser !== "Gjest" && isPincodePresentIconCheck() ? (
                <StarIcon
                  sx={{
                    position: "absolute",
                    left: "35px", // Adjust for left-side positioning
                    fontSize: "35px",
                    marginTop: "-10px",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    addToFavourite();
                  }}
                />
              ) : (
                <StarOutlineIcon
                  sx={{
                    position: "absolute",
                    left: "35px", // Adjust for left-side positioning
                    fontSize: "35px",
                    marginTop: "-10px",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    addToFavourite();
                  }}
                />
              )}

              {currentUser !== "Gjest" && (
                <BorderColorIcon
                  sx={{
                    position: "absolute",
                    right: "35px",
                    fontSize: "35px",
                    marginTop: "-10px",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    editUser();
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderSearcher;

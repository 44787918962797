import React, { useState, useContext, useEffect } from "react";
import { Grid, Box, Button, TextField, Container } from "@mui/material";

import ClubHouseLogo from "../../assets/img/clubhouse_logo.png";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/Context";
import LoginIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";

import { SocketContext } from "../../context/SocketContext";

import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLogin,
    updateLogin,
    modifyOperator,
    isBlocking,
    BlockingScreen,
    updateSession,
    modifyOperatorFull,
    modifyOperatorInfo,
    modifyHall,
    updatePrinter,
    updateCardID,
    updateOKBingoID,
    updateIsOKBingoEnabled,
    enviroment,
  } = useContext(AppContext);

  var isLoading = false;
  const { socket } = useContext(SocketContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginTask = async () => {
    if (isLoading) {
      return;
    }

    if (!username || !password) {
      toast.error(t("error.empty"));
      return;
    }

    isLoading = true;

    let dataRequest = {
      username: username,
      password: password,
    };

    try {
      const hostname = window.location.hostname;
      var url = "https://stage01-operator.vpfscandinavia.com";

      if (hostname === "localhost") {
        url = "https://stage01-operator.clubhouse.no/api/login/";
      } else if (hostname.includes("stage01")) {
        url = "https://stage01-operator.clubhouse.no/api/login/";
      } else if (hostname.includes("stage02")) {
        url = "https://stage02-operator.clubhouse.no/api/login/";
      } else {
        url = "https://operator.clubhouse.no/api/login/";
      }

      var response = await axios({
        method: "post",
        url: url,
        data: dataRequest,
      });
      if (
        response.data.hall.pinPadSerialNumber === null ||
        response.data.hall.pinPadSerialNumber === "null"
      ) {
        updateCardID("");
      } else {
        updateCardID(response.data.hall.pinPadSerialNumber);
        //updateCardID("");
      }

      var dataPrint = {
        text: "",
        name: response.data.printer.name,
        id: response.data.printer.id,
        ip: response.data.printer.networkAddress,
      };
      updatePrinter(dataPrint);

      //const cookieString = response.headers["set-cookie"][0];
      //const sessValue = cookieString.split(";")[0].split("=")[1];

      const sessValue = response.data.operator.auth;
      response.data.session = sessValue;

      //Temp get countries
      //socket.emit("getCountries", response.data);

      if (!response) {
        isLoading = false;
        toast.error(t("error.login"));
        return;
      }

      updateSession(sessValue);
      modifyOperator(response.data.operator.username);
      modifyOperatorFull(response.data.operator.firstName);
      modifyHall(response.data.hall.name);
      modifyOperatorInfo(response.data);

      isLoading = false;

      var dataSocket = {
        operator: username,
        password: password,
        sessionID: sessValue,
        operatorInfo: response.data,
      };
      socket.emit("registerLogin", dataSocket);

      //navigate("/salgs");
    } catch (err) {
      console.error(err);
      isLoading = false;
      toast.error(t("error.login"));
      return;
    }

    /*
    var data = {
      username: username,
      password: password,
      action: "login",
    };
    socket.emit("login", data);
    */
  };

  useEffect(() => {
    const handleLogin = (data) => {
      if (!data) {
        isLoading = false;
        toast.error(t("error.login"));
        return;
      }

      var dataPrint = {
        text: "",
        name: data.printer.name,
        id: data.printer.id,
        ip: data.printer.networkAddress,
      };
      updatePrinter(dataPrint);

      if (!data) {
        isLoading = false;
        toast.error(t("error.login"));
        return;
      }
      modifyOperator(data.operator.username);
      modifyOperatorFull(data.operator.firstName);
      modifyHall(data.hall.name);
      updateLogin(true);
      updateSession(data.session);
      isLoading = false;

      socket.emit("registerLogin", data);
      //navigate("/salgs");
    };

    const handleGetCountries = (data) => {
      //console.log("countries");
      //console.log(data);
    };

    const handleRegisterLogin = (data) => {
      if (
        data.operatorInfo.hall.pinPadSerialNumber &&
        data.operatorInfo.hall.pinPadSerialNumber !== ""
      ) {
        updateCardID(data.bingoName);
      }

      console.log("bingoList", data.bingoList);

      for (var i = 0; i < data.bingoList.length; i++) {
        if (
          data.bingoList[i].bingoName === data.operatorInfo.hall.name &&
          data.bingoList[i].isEnabled
        ) {
          console.log("bingoID", data.bingoList[i].bingoID);
          console.log("bingoName", data.bingoList[i].bingoName);
          console.log("isEnabled", data.bingoList[i].isEnabled);
          updateOKBingoID(data.bingoList[i].bingoID);
          updateIsOKBingoEnabled(true);
          break;
        }
      }

      updateLogin(true);
      navigate("/salgs");
    };

    socket.on("registerLogin", handleRegisterLogin);
    socket.on("login", handleLogin);
    socket.on("getCountries", handleGetCountries);

    return () => {
      socket.on("registerLogin", handleRegisterLogin);
      socket.off("login", handleLogin);
      socket.off("getCountries", handleGetCountries);
    };
  }, [socket]);

  useEffect(() => {
    if (isLogin) {
      navigate("/salgs");
      return;
    }

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleFormSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleFormSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
  };

  return (
    <>
      {isBlocking && <BlockingScreen />}

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Toaster position="top-center" reverseOrder={false} />

        <Box
          component="img"
          sx={{
            height: "300px",
            width: "auto",
            paddingTop: 0,
          }}
          alt=""
          src={ClubHouseLogo}
        />

        <form
          noValidate
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "400px",
            width: "400px",
            minWidth: "400px",
            justifyContent: "center",
          }}
          onSubmit={handleFormSubmit}
        >
          <Grid container direction={"column"} spacing={5}>
            <Grid item style={{ width: "100%" }}>
              <TextField
                autoFocus
                sx={{
                  width: "100%",
                }}
                label={t("login.username")}
                variant="outlined"
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{
                  width: "100%",
                }}
                label={t("login.password")}
                type="password"
                autoComplete="current-password"
                variant="outlined"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color={isLoading ? "disabled" : "primary"}
                sx={{
                  height: "60px !important",
                  width: "100%",
                  backgroundColor: "#2f49cf",
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                startIcon={<LoginIcon />}
                onClick={() => {
                  loginTask();
                }}
              >
                {t("login.login")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default Login;

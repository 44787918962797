import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";

import ClubHouseLogo from "../../assets/img/clubhouse_logo.png";
import { useTranslation } from "react-i18next";

import { SocketContext } from "../../context/SocketContext";

import toast, { Toaster } from "react-hot-toast";
import CalculatorItem from "./CalculatorItem";
import axios from "axios";

function UserLogin() {
  const { t } = useTranslation();

  const [isStandBy, setIsStandBy] = useState(true);
  const [isPincode, setIsPincode] = useState(false);
  const [isSignature, setIsSignature] = useState(false);
  const [url, setUrl] = useState("");

  const [currentBingoName, setCurrentBingoName] = useState("");

  const currentBingoNameRef = useRef(currentBingoName);
  useEffect(() => {
    currentBingoNameRef.current = currentBingoName;
  }, [currentBingoName]);

  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);

  const getUrlParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const hallParam = getUrlParameter("hall");
    if (hallParam) {
      setCurrentBingoName(hallParam);
    }
    const hostname = window.location.hostname;

    if (hostname === "localhost") {
      setUrl("https://stage01-operator.vpfscandinavia.com/api/new-signature");
    } else if (hostname.includes("stage01")) {
      setUrl("https://stage01-operator.vpfscandinavia.com/api/new-signature");
    } else if (hostname.includes("stage02")) {
      setUrl("https://stage02-operator.vpfscandinavia.com/api/new-signature");
    } else {
      setUrl("https://operator.vpfscandinavia.com/api/new-signature");
    }
  }, []);

  const getCoordinates = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (event.touches) {
      // Touch event
      const touch = event.touches[0]; // Get the first touch
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      };
    } else {
      // Mouse event
      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    }
  };

  const startDrawing = (event) => {
    const coordinates = getCoordinates(event);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(coordinates.x, coordinates.y);
    setDrawing(true);
  };

  const draw = (event) => {
    if (!drawing) return;
    event.preventDefault(); // Prevent scrolling when touching
    const coordinates = getCoordinates(event);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineTo(coordinates.x, coordinates.y);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setDrawing(false);
  };

  const reset = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clears the entire canvas
  };

  const { socket } = useContext(SocketContext);

  var send = false;
  useEffect(() => {
    const handleSendPincode = (data) => {
      send = false;
      if (!data) {
        toast.error("Feil Kode");
      }
    };

    const handleActivateSignature = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(false);
        setIsSignature(true);
      }
    };

    const handleActivateSMS = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(true);
        setIsStandBy(false);
        setIsSignature(false);
        setAmount("");
      }
    };

    const handleDeactivateSignature = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
        reset();
      }
    };

    const handleDeactivateSMS = (data) => {
      if (data.bingoName === currentBingoNameRef.current) {
        setIsPincode(false);
        setIsStandBy(true);
        setIsSignature(false);
      }
    };

    const handleUploadImageSignature = (data) => {
      reset();
      backToStandBy();
      socket.emit("newSignature", {
        bingoName: currentBingoName,
        uploadID: data.uploadID,
        url: data.url,
      });
    };

    // Attach event listeners
    socket.on("sendPincode", handleSendPincode);
    socket.on("activateSMS", handleActivateSMS);
    socket.on("activateSignature", handleActivateSignature);
    socket.on("deactivateSMS", handleDeactivateSMS);
    socket.on("deactivateSignature", handleDeactivateSignature);
    socket.on("uploadImageSignature", handleUploadImageSignature);

    return () => {
      socket.off("sendPincode", handleSendPincode);
      socket.off("activateSMS", handleActivateSMS);
      socket.off("activateSignature", handleActivateSignature);
      socket.off("deactivateSMS", handleDeactivateSMS);
      socket.off("deactivateSignature", handleDeactivateSignature);
      socket.off("uploadImageSignature", handleUploadImageSignature);
    };
  }, [socket]);

  const [amount, setAmount] = useState("");

  useEffect(() => {
    /*
    if (send) {
      return;
    }
    if (amount.length === 4) {
      send = true;
      socket.emit("sendPincode", {
        bingoName: currentBingoName,
        pincode: amount,
      });

      setAmount("");
      backToStandBy();
    }
    */
  }, [amount]);

  const updateAmount = (value) => {
    if (value === "confirm") {
      if (send) {
        return;
      }
      if (amount.length === 4) {
        send = true;
        socket.emit("sendPincode", {
          bingoName: currentBingoName,
          pincode: amount,
        });

        setAmount("");
        backToStandBy();
      }
      return;
    }
    if (amount.length >= 4 && value !== "backspace") {
      return;
    }

    if (amount === "" && value === "backspace") {
      return;
    }
    /*
    if (amount === "0" && value !== "backspace") {
      setAmount(value);
      return;
    }
    if (amount === "0" && value === "backspace") {
      return;
    }
    */
    if (value === "backspace" && amount.length === 1 && amount !== 0) {
      setAmount("");
      return;
    }
    if (value === "backspace" && amount.length > 1 && amount !== 0) {
      setAmount(amount.substring(0, amount.length - 1));
      return;
    }

    setAmount(amount + value);
  };

  const backToStandBy = () => {
    setIsPincode(false);
    setIsStandBy(true);
    setIsSignature(false);
  };

  const sendCanvasAsBlob = () => {
    const canvas = canvasRef.current;
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const milliseconds = now.getMilliseconds().toString().padStart(3, "0");

    var filename = `signature_${year}${month}${day}${hours}${minutes}${seconds}.png`;

    // Convert canvas to data URL
    const imageUrl = canvas.toDataURL("image/png");

    // Convert the data URL to a buffer
    const byteString = atob(imageUrl.split(",")[1]);
    const mimeType = imageUrl.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let j = 0; j < byteString.length; j++) {
      intArray[j] = byteString.charCodeAt(j);
    }

    // Emit the image data to the server
    socket.emit("uploadImageSignature", {
      fileBuffer: intArray,
      fileName: filename,
    });
    /*
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, filename);

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          reset();
          backToStandBy();
          socket.emit("newSignature", { bingoName: currentBingoName });
        })
        .catch((error) => {
          toast(t("error.error"));
        });
    }, "image/png");
    */
  };

  const getRandomPosition = (max) => Math.floor(Math.random() * max);

  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      const newTop = getRandomPosition(window.innerHeight - 300); // Adjust based on logo height
      const newLeft = getRandomPosition(window.innerWidth - 300); // Adjust based on logo width
      setPosition({ top: newTop, left: newLeft });
    };

    const intervalId = setInterval(updatePosition, 3000); // Update position every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        marginTop: 0,
        backgroundColor: "#000", // Black background
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />

      {isPincode && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              padding: "10px",
              height: "calc(100% - 55px)",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              alignItems: "center",
              color: "white", // White text color
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: "100%",
                width: "100%",
                marginTop: 0,
                marginLeft: 0,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "85px",
                  fontSize: "25px",
                  fontWeight: "bold",
                  flexDirection: "row",
                  marginTop: 0,
                  color: "white", // White text color
                }}
              >
                <Grid
                  container
                  flexDirection="row"
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    marginTop: 0,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: "10px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "75px",
                      fontSize: "19px",
                      fontWeight: "bold",
                      flexDirection: "row",
                      marginTop: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        color: "white", // White text color
                      }}
                    >
                      Skriv inn PIN-koden din
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: 0,
                  padding: "10px",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <TextField
                  type="password"
                  value={amount}
                  sx={{
                    "& label": {
                      position: "relative !important",
                      fontSize: "22px !important",
                      color: "white !important",
                    },
                    "& label.Mui-focused": {
                      position: "relative !important",
                      color: "white !important",
                    },
                    "& input": {
                      color: "white !important",
                      border: "3px solid white",
                      borderRadius: "4px",
                      height: "50px !important",
                      width: "275px !important",
                      fontSize: "40px !important",
                      textAlign: "center",
                    },
                    "& input.Mui-focused": {
                      color: "white !important",
                      border: "3px solid white",
                      borderRadius: "4px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              maxWidth: "100% !important",
              height: "100%",
              backgroundColor: "#1b1c20",
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: "180px",
                width: "100%",
                marginTop: 0,
                marginLeft: 0,
                padding: "10px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={"1"} />
              <CalculatorItem updateAmount={updateAmount} value={"2"} />
              <CalculatorItem updateAmount={updateAmount} value={"3"} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: "180px",
                width: "100%",
                marginTop: 0,
                marginLeft: 0,
                padding: "10px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={"4"} />
              <CalculatorItem updateAmount={updateAmount} value={"5"} />
              <CalculatorItem updateAmount={updateAmount} value={"6"} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: "180px",
                width: "100%",
                marginTop: 0,
                marginLeft: 0,
                padding: "10px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={"7"} />
              <CalculatorItem updateAmount={updateAmount} value={"8"} />
              <CalculatorItem updateAmount={updateAmount} value={"9"} />
            </Grid>
            <Grid
              container
              flexDirection="row"
              sx={{
                height: "180px",
                width: "100%",
                marginTop: 0,
                marginLeft: 0,
                padding: "10px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-around",
                marginBottom: "20px",
              }}
            >
              <CalculatorItem updateAmount={updateAmount} value={"backspace"} />
              <CalculatorItem updateAmount={updateAmount} value={"0"} />
              <CalculatorItem updateAmount={updateAmount} value={"confirm"} />
            </Grid>
          </Grid>
        </>
      )}

      {isStandBy && (
        <>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
              marginTop: 0,
              backgroundColor: "#000",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
                height: "calc(100% - 75px)",
                width: "100%",
                marginTop: 0,
                marginLeft: 0,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                alignItems: "center",
                color: "white",
                position: "relative",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: "300px",
                  width: "auto",
                  paddingTop: 0,
                  position: "absolute",
                  top: `${position.top}px`,
                  left: `${position.left}px`,
                  transition: "top 2.5s ease, left 2.5s ease", // Smooth transition
                }}
                alt=""
                src={ClubHouseLogo}
              />
            </Grid>
          </Container>
        </>
      )}

      {isSignature && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              height: "calc(100% - 75px)",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <canvas
              ref={canvasRef}
              width={540}
              height={825}
              style={{
                border: "1px solid #000000",
                background: "white",
              }}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseOut={stopDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
              onTouchCancel={stopDrawing}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          ></Grid>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row", // Ensures that items are in a row
              flexWrap: "wrap", // Allows items to wrap when necessary
              justifyContent: "center", // Center align items horizontally
              alignItems: "center", // Center align items vertically
              width: "100%", // Set width to 100% of the viewport
              padding: "10px 0", // Add some padding top and bottom
            }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center", // Center content within each grid
                alignItems: "center", // Align items vertically
                padding: "10px", // Add padding around each button
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  width: "200px",
                  height: "90px",
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  backgroundColor: "#009e82",
                }}
                onClick={() => {
                  sendCanvasAsBlob();
                }}
              >
                Fullfør
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  height: "90px",
                  width: "180px",
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  backgroundColor: "#fcad89",
                }}
                onClick={reset}
              >
                Nullstill
              </Button>
            </Grid>
          </Container>
        </>
      )}
    </Container>
  );
}

export default UserLogin;

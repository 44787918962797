export const TRANSLATIONS_NO = {
  login: {
    username: "Brukernavn",
    password: "Passord",
    login: "Logg inn",
  },
  header: {
    sales: "Salgs",
    newMember: "Nytt Medlem",
    searchMember: "Søk Medlemmer",
    onlinePayment: "Online Utbetaling",
    onlinePaymentHistory: "Online Utbetaling Historikk",
    report: "Rapport",
    playerWithBalanceReport: "Spiller med balanse rapport",
    adminReport: "Admin Rapport",
    openTickets: "Billetter i dag",
    ticketHistoric: "Billet Historikk",
    recent: "Siste salg",
    endShift: "Avslutte skiftet",
    endShiftMessage: "Vil du avslutte skiftet?",
    lastTransactions: "Siste trans.",
    lastCardTransactions: "Kort trans.",
    specialGame: "Spesialspill",
    specialGameSubtitle: "Skriv premiebeløpet",
    specialGameMessage: "Dette vil endre premien for det siste spillet til",
    specialGameMessageError: "Det er ikke noe åpent spesialspill",
    printLastTrans: "Skriv ut siste salg",
    userLimits: "Totalgrenser",
  },
  sales: {
    transactions: "Transaksjoner",
    printAction: "Skriv Ut",
    scanMessage: "Skann eller skriv inn brukernummeret",
    search: "Søk medlemmer",
    searchMemberPin: "Søk etter pin",
    searchMemberUsername: "Søk etter brukernavn",
    searchMemberPhonenumber: "Søk etter telefonnummer",
    autoSearch: "Automatisk søk",
    searchByLastname: "Søk etter etternavn",
    searchByFirstname: "Søk etter fornavn",
    deposit: "Sett inn",
    depositSubtitle: "På spillerkonto",
    withdraw: "Ta ut",
    withdrawSubtitle: "Fra spillerkonto",
    transfer: "Overføring",
    transferSubtitle: "Til bankkonto",
    newTicket: "Ny Billett",
    payout: "Utbetaling",
    scan: "Skann",
    refill: "Fylle på",
    product: "Nytt produkt",
    cash: "Contant",
    card: "Kort",
    paymentCard: "Fullfør kjøpet og betal med kort?",
    paymentCash: "Fullfør kjøpet og betal kontant?",
    spillerkonto: "Spillerkonto",
    printMovement: "Konto-Utskrift",
    saldoUserAccount: " Saldo på spillerkonto: ",
    newCardMessage:
      "Dette kortet er ikke registrert, vil du opprette en ny bruker?",
    yes: "Ja",
    no: "Nei",
    part: "Delvis",
    partialAmount: "Velg beløp",
    confirm: "Bekreft",
    cancel: "Avbryt",
    cardPaymentItem: "Kontant uttak",
    confirmWG:
      "For å fortsette må du først fullføre operasjonen i Win Games-kassen.",
    confirmOK:
      "For å fortsette må du først fullføre operasjonen i OK Databingo-kassen.",
    confirmNT:
      "For å fortsette må du først fullføre operasjonen i Norsk Tipping-kassen.",
    NTInn: "Inn",
    NTUt: "Ut",
    total: "Totalt",
    payCard: "Mottat Kort",
    payCash: "Mottat Contant",
    change: "Tilbake",
    remaining: "Gjenstående",
    paymentCardScreen: "Betaling med kort",
    paymentCashScreen: "Betaling med kontanter",
    paymentFullCard: "Fyll inn hele summen",
    paymentFullCash: "Fyll inn hele summen",
    amount: "Beløp",
    endShop: "Bekrefter du at transaksjonen er utført?",
    endShopByCard:
      "Bekrefter du at transaksjonen er utført? Etter bekreftelse, vent på brukerbetalingen",
    insufficientFunds: "Transaksjonene er ikke betalt ennå",
    waiting: "Venter på brukerbetalingen",
    cancelShop: "Avbryt kjøp",
    print: "Skriv ut kvittering",
    shortcuts: "Hurtigknapper beløp",
    payCard: "Betal med kort",
    payCardSingle: "Enkel Betal med kort",
    payCash: "Betal med Cash",
    payAccount: "Betal med konto",

    payCHTitle: "Betal med Spillerkonto",
    payCH: "Spiller-Konto",
    payoutTicket: "Åpne billetter",
    registerNewAnonUser: "Ny anonym bruker",
    registerNewHybridUser: "Ny daglig bruker",
    registerNewMember: "Ny medlem",
    editHybridUser: "Rediger daglig bruker",
    editMember: "Rediger medlem",

    anonToUser:
      "Vil du redigere denne daglig brukeren eller gjøre den om til medlem?",
    hybridToUser:
      "Vil du redigere denne daglig brukeren eller gjøre den om til medlem?",
    optional: "(valgfri)",
    userTooSmall: "Søk skal ha minst 7 sifre",
    userNotFound: "Brukeren eksisterer ikke, vil du opprette en ny? ",
    usersFound: "Mer enn en bruker er funnet",
    selectUser: "Bruk denne brukeren",
    registerNewUserConfirm: "Registrer ny bruker",
    editUserConfirm: "Rediger bruker",
    phonenumber: "Telefonnummer",
    phonenumberMandatory: "Telefonnummer (påbudt)",
    newUser: "Ny dadlig bruker",
    newMember: "Nytt medlem",
    updateHybridUser:
      "Dette er en daglig bruker, du kan redigere den eller registrere den som et nytt medlem:",
    lastUsers: "Siste brukere",
    sellMessage: "Husk å motta kontanter fra kunden før du trykker TOTAL",
    sellMessageCash1: "Husk å ta ",
    sellMessageCash2: " KR fra kunden. ",
    sellMessageReturnCash1: "Husk å gi tilbake ",
    sellMessageReturnCash2: " KR til kunden.",
    name: "Navn",
    nameMandatory: "Navn (påbudt)",
    surname: "Etternavn",
    nameAndSurname: "Navn og etternavn",
    unblock: "Send bekreftelse til brukeren for å låse opp billettene",
    send: "Send",
    unregistered: "Uregistrert",
    registerAnonPlayer: "Registrer den daglige brukeren",
    paymentAnon:
      "Daglige brukere kan kun betale med kort, vil du registrere denne daglige brukeren?",
    registerAnonPlayerConfirm: "Registrer",
    transactionDone: "Transaksjonen er fullført",
    backToPayment: "Tilbake til betalingen",
    transactionFail: "Transaksjonen mislyktes eller avvistes",
    smsTitle: "Engangskoden sendt",
    smsText1: "SMS med engangskode er sendt til kundens mobilnummer: ",
    smsText2: "Be kunden taste mottatt kode på terminalen.",
    pincodeTitle: "Venter på brukerens pinkode",
    pincodeText1:
      "Brukeren bør introdusere den 4-sifrede pinkoden knyttet til kontoen sin: ",
    userConfirm: "Bruker registrert vellykket",
    selected: "Valgt",
    internetAccess: "Forby internettilgang",
    chooseOne: "Velg...",
    passport: "Pass",
    driverLicense: "Førerkort",
    electronicID: "Electronic(BankID)",
    bankCard: "Bankkort",
    other: "Annet",
    terms: "Godkjenner kommunikasjonsbetingelsene",
    cardID: "Kort ID",
    username: "Brukernavn",
    firstname: "Fornavn",
    lastname: "Etternavn",
    address1: "Adresse 1",
    address2: "Adresse 2",
    verificationMethod: "Verifiseringsmetode",
    country: "Land",
    postalCode: "Postnummer",
    region: "Sted",
    email: "E-post",
    birthdate: "Fødselsdato",
    verificationID: "Verifiserings-ID",
    cashoutPending: "Brukeren har en ventende utbetaling:",
    cashoutNow: "Utbetale nå",
    signatureTitle: "Signatur kreves",
    signatureText:
      "Gjeldende transaksjon må signeres for å bli godkjent. Venter på brukerens signatur.",
    signatureTitleID: "ID-bekreftelse påkrevd",
    signatureTextID:
      "En ID-bekreftelse er påkrevd for å fortsette. Ta et bilde av brukerens ID for å verifisere brukerens identitet.",
    signatureConfirmSMS: "En pinkode vil bli sendt til brukerens telefon",
    signatureUserNeedsMobile:
      "Denne brukeren har ikke registrert et telefonnummer. Et telefonnummer er obligatorisk for å fortsette. ",
    signatureConfirm: "Transaksjoner godkjent",
    openSessionTitle: "Åpen økt funnet",
    openSessionText1: "Denne billetten har en åpen økt i terminalen ",
    openSessionText2: " med en total mengde ",
    openSessionTextClose: "Vil du lukke økten?",
    openSessionTextUnclosable: "Denne økten må lukkes i terminalen først",
    chooseProduct: "Velg produkt",

    ticket7Pincode: "Billet Pincode",
    currentSaldo: "Gjeldende saldo",
    editHybridToFull: "Gjør om til medlem",
    userLimits: "Totalgrenser",
    noLimits: "Ingen begrensning",
    cashIn: "Innskudd",
    cashOut: "Uttak",
    limitsUpdated: "Begrensningene for denne brukeren ble oppdatert",
    cardToCash: "Kontant uttak",
    limitDaily: "Daglig grense",
    limitMonthly: "Månedlig grense",
    isLimitSurpassed: "Grensen er overskredet",
    isLimitSurpassedDaily:
      "Denne brukeren har overskredet grensen og har ikke lov til å spille mer i dag",
    isLimitSurpassedMonth:
      "Denne brukeren har overskredet grensen og har ikke lov til å spille mer denne måneden",
    isVerificationNeeded: "Verifisering er nødvendig",
    isVerificationNeededText:
      "Brukeren må verifiseres for å fortsette, rediger brukeren for å verifisere den.",
    isLimitSurpassedTransaction1:
      "Den nåværende transaksjonen er ikke tillatt fordi den overstiger brukerens grense. ",
    isLimitSurpassedTransaction2: "Det maksimale beløpet som er tillatt er: ",

    ticketWithDifferentParentTitle:
      "Denne billetten tilhører ikke denne brukeren",
    ticketWithDifferentParentText:
      "Denne billetten tilhører en annen bruker, vil du foreta utbetalingen til den nåværende brukeren?",
    photoVerificationTitle: "Denne brukeren har ikke et verifisert bilde",
    photoVerificationText:
      "Vil du verifisere brukerens bilde? Ta et bilde av brukeren eller brukerens ID for å verifisere brukerens identitet.",
    photoUploadTitle: "Ta bildet",
    photoUploadText:
      "Bruk et av kameraene til å ta et bilde av brukerens ansikt eller et annet gyldig dokument, som pass eller ID-kort.",
    photoUploadTextSignature:
      "Bruk et av kameraene til å ta et bilde av brukerens gyldig dokument, som pass eller ID-kort.",
    photoUploadSuccess: "Bildet ble lastet opp",
    userVerificationImage: "Brukerverifiseringsbilde",
    userVerificationImageNew: "Ta et nytt bilde",
    saldo: "Saldo",
    status: "Status",
    open: "Åpen",
    closed: "Lukket",
    inGame: "I spill",
    editUserBlock: "Fullfør den nåværende operasjonen for å redigere brukeren",
    bigNumberConfirmTitle: "Transaksjon over 10.000",
    bigNumberConfirmText1:
      "Beløpet for denne transaksjonen er høyere enn vanlig, vil du fortsette?",
    bigNumberConfirmText2: "Totalbeløp: ",
    deleteUser: "Slett bruker",
    deleteUserTitle:
      "Du er i ferd med å slette denne brukeren, er du sikker på at du vil fortsette?",
    deleteUserConfirm: "Brukeren har blitt slettet",
  },
  error: {
    empty: "Felter skal ikke være tomme",
    login: "Brukenavn eller passord er feil",
    blockProvider:
      "Det er ikke mulig å selge billetter fra to forskjellige databingoleverandører for samme bruker eller mer enn en billett",
    userNotLogin: "Du må logge på en bruker først",
    userNotMember: "Denne brukeren er ikke medlem av ClubHouse",
    error:
      "Noe gikk galt. Prøv igjen om et øyeblikk eller kontakt brukerstøtten.",
    errorTooSmall: "Søket med pinne må ha minst 7 sifre",
    errorPhonenumber: "Søket etter telefonnummer må være 7 sifre",
    errorPhonenumberInput:
      "Telefonnummeret er ikke gyldig. Bruk et gyldig norsk 8-sifret nummer",
    errorUsernameInput: "Brukernavn kan ikke være tomt",
    errorCardIDEmpty: "Kort-ID kan ikke være tom",
    errorCardIDFormat: "Kort-ID-formatet er feil",
    errorUsernameEmpty: "Brukernavnet kan ikke være tomt",
    errorUsernameTooShort: "Brukernavnet er for kort, minimum 5 tegn",
    errorUsernameTooLong: "Brukernavnet er for langt, maksimum 12 tegn",
    errorFirstnameEmpty: "Fornavnet kan ikke være tomt",
    errorLastnameEmpty: "Etternavnet kan ikke være tomt",
    errorAddress1Empty: "Adressen kan ikke være tom",
    errorPostalCodeEmpty: "Postnummer kan ikke være tomt",
    errorRegionEmpty: "Regionen kan ikke være tom",
    errorEmailEmpty: "E-post kan ikke være tom",
    errorBirthdateEmpty: "Fødselsdatoen kan ikke være tom",
    errorBirthdateFormat: "Fødselsdato formatet er feil",
    errorVerificationIDEmpty: "Bekreftelses-ID kan ikke være tom",
    errorVerificationMethodEmpty: "Bekreftelsesmetoden kan ikke være tom",
    errorPhonenumberExists: "Telefonnummer allerede registrert",
    errorUsernameExists: "Brukernavn allerede registrert",
    errorCardIDExists: "Kort-ID allerede registrert",
    errorSSNIDExists: "Fødselsnummer allerede registrert",
    errorUserFailCode: "Koden introdusert av brukeren er ikke korrekt",
    errorRefreshTransactions:
      "Du kan ikke starte kassereren på nytt med ventende transaksjoner",
    errorNavigateTransactions:
      "Du kan ikke bytte skjerm med en bruker som er logget inn eller med pågående transaksjoner",
    errorCode: "Feilkode",
    errorLimitSize:
      "Den daglige grensen kan ikke være lavere enn den månedlige grensen",
    errorEmptyLimits: "Begrensningene kan ikke være tomme",
    errorImageRegister:
      "Du må verifisere brukerens bilde før du fullfører registreringen",
    errorSSNIDInvalid: "SSN-ID-en er ikke gyldig",
    errorSSNIDUnderAge: "Brukeren er under 18 år",
    errorCancelOpenSell:
      "For å kansellere den valgte transaksjonen, må du fullføre dette salget eller gå tilbake til hovedskjermen",
  },
  sisteSalgs: {
    date: "Dato",
    bingoName: "Hall",
    operator: "Operatør",
    payCard: "Betalt med kort",
    payCash: "Betalt med Cash",
    payAccount: "Betalt med konto",
    total: "Totalt",
    change: "Tilbake",
    userID: "Bruker ID",
    ticket7Pincode: "Billet Pincode",
    ticketID: "Billet ID",
    username: "Brukernavn",
    amount: "Beløp",
    printed: "Trykt",
    transactions: "Se transaksjoner",
    description: "Beskrivelse",
    printAgain: "Skriv ut",
    backToSells: "Tilbake til salg",
  },
  sisteTrans: {
    date: "Dato",
    bingoName: "Hall",
    operator: "Operatør",
    payCard: "Betalt med kort",
    payCash: "Betalt med Cash",
    total: "Totalt",
    change: "Tilbake",
    ticket7Pincode: "Billet Pincode",
    ticketID: "Billet ID",
    username: "Brukernavn",
    amount: "Beløp",
    printed: "Trykt",
    transactions: "Se transaksjoner",
    description: "Beskrivelse",
    printAgain: "Skriv ut",
  },
  ticketHistoric: {
    ticketToSearch: "7-sifret billett for å søke",
    date: "Dato",
    action: "Aksjon",
    openSession: "Åpnet økt",
    closeSession: "Lukket økt",
    payment: "Kjøp kort",
    win: "Vinn premie",
    cancel: "Avbryt kort",
    deposit: "Innskudd",
    depositFromPreviousSession: "Innskudd fra forrige økt",
    saldoInit: "Startbalanse",
    saldoEnd: "Endelig balanse",
    bingoName: "Hall",
    gameID: "Spill-ID",
    valueChange: "Verdiendring",
  },
  apneBilleter: {
    date: "Dato",
    bingoName: "Hall",
    operator: "Operatør",
    ticket7Pincode: "Billet Pincode",
    ticketID: "Billet ID",
    user7Pincode: "Bruker",
    description: "Beskrivelse",
    amount: "Beløp",
    printAgain: "Skriv ut",
    seeTransactions: "Se transaksjoner",
    username: "Brukernavn",
  },
  menu: {
    search: "Søk",
  },
  report: {
    startDay: "Det er ikke åpent økten. Start et nytt nå.",
    startDayButton: "Start økten.",
    confirm: "Bekreft",
    edit: " Rediger",
    cashin: "Innskudd",
    cashout: "Uttak",
    neto: "Neto",
    next: "Neste",
    back: "Tilbake",
    picture: " Bilde",
    errorTicket: "Du må laste opp billettene først.",
    capturePicture: "Ta bilde",
    resetCamera: "Tilbakestill kamera",
    date: "Dato",
    employee: "Ansatt",
    miniSafeYesterday: "Minisafe i går",
    cashierYesterday: "Kassebeholding i går",
    cashierInOpening: "Kassebeholdning ved åpning",
    difference: "Differanse",
    cashierTotal: "Kasse totalt",
    cashierRefill: "Kassepåfyll",
    sendReport: "Send rapport",
    theoric: "Teoretisk",
    minisafe: "Minisafe",
    cashier: "Kassebeholding",
    deviated: "Avvik",
    acceptDeviated: "Godjkenner avvik",
    closeReport: "Dagsrapport slutt",
  },
  endShift: {
    title: "Velg ett av alternativene:",
    subtitle:
      "Du må gjøre begge deler, kortterminalrapporten og daglig kasserapport",
    cardReport: "Kortterminal rapport",
    cashierReport: "Clubhouse Rapport",
    completed: "Fullført",
    notCompleted: "Ikke fullført",
    confirmCardReport: "Vil du bekrefte kortterminalrapporten?",
    cardPayment: "Betaling med kort",
    cardPayout: "Kontant uttak",
    cardTotal: "Totalt med kort",
    waiting: "Behandler rapporten, vent et øyeblikk.",
  },
  socketStatus: {
    disconnectTitle: "Tilkobling tapt",
    disconnectText:
      "Tilkoblingen til serveren har blitt brutt. Prøver å koble til igjen, vent eller kontakt support.",
    reconnectTitle: "Tilkoblet igjen",
    reconnectTextNoTransactions:
      "Tilkoblingen til serveren har blitt gjenopprettet. Vi anbefaler at du oppdaterer kassen.",
    reconnectTextTransactions:
      "Tilkoblingen til serveren har blitt gjenopprettet. Vi anbefaler at du oppdaterer kassen etter å ha fullført de pågående transaksjonene.",
    refresh: "Oppdater nå",
    notRefresh: "Ikke oppdater",
  },
};
